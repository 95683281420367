<template>
    <v-layout column>
        <v-flex>
            <h2>
                <v-icon class="mr-2">mdi-credit-card-outline</v-icon>Expense And Income Report
            </h2>
        </v-flex>
        <v-flex class="mx-6">
            <alert-message v-if="msg" :error="msg"></alert-message>
        </v-flex>
        <v-container>
            <v-flex>
                <v-layout align-center wrap>
                    <v-flex xs5 sm3 class="ml-3">
                        <input-date @clear="startDateCleared = true" v-model="startDate"
                            label="Start Date"></input-date>
                    </v-flex>
                    <v-flex xs5 sm3 class=" ml-3 mr-5">
                        <input-date @clear="endDateCleared = true" v-model="endDate" label="End Date"></input-date>
                    </v-flex>
                    <v-flex class=" ml-3">
                        <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex>
                <v-container>
                    <v-layout v-if="items.length > 0" column>
                        <v-flex text-center>
                            <h2 class="report-heading">Expense And Income Report with Transactions</h2>
                        </v-flex>
                        <v-flex text-right>
                            <export-to-csv :data="items" :fileName="`expense-and-income-report`"></export-to-csv>
                            <export-to-xls :data="items" :fileName="`expense-and-income-report`"></export-to-xls>
                        </v-flex>
                        <v-simple-table v-if="$vuetify.breakpoint.smAndUp">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Transactions</th>
                                    <th class="text-right">Debit (₹)</th>
                                    <th class="text-right">Credit (₹)</th>
                                </tr>
                            </thead>
                            <tbody v-for="item in items" :key="item.date">
                                <tr v-for="data in item.transactions" :key="data._id">
                                    <td>
                                        {{ item.date | dateFormat }}
                                    </td>
                                    <td>
                                        {{ data.narration }}
                                    </td>
                                    <td class="text-right">
                                        <span v-if="data.txType == 'DR'">{{ data.txAmount }}</span>
                                    </td>
                                    <td class="text-right">
                                        <span v-if="data.txType == 'CR'">{{ data.txAmount }}</span>
                                    </td>
                                </tr>
                                <tr v-if="item.length != 0">
                                    <td colspan="2"><b>Total</b></td>
                                    <td class="text-right font-weight-bold">{{ item.expense }}/-</td>
                                    <td class="text-right font-weight-bold">{{ item.income }}/-</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-layout>
                    <template v-if="$vuetify.breakpoint.xsOnly">
                        <span v-for="item in items" :key="item.date">
                            <card-view v-if="item.length != 0" :data="item"></card-view>
                        </span>
                    </template>
                    <v-flex text-center>
                        <progress-bar :show="loading"></progress-bar>
                    </v-flex>
                </v-container>
            </v-flex>
        </v-container>
    </v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
import CardView from '@/components/ExpenseAndIncomeResponsiveCard'
export default {
    components: {
        ExportToCsv,
        ExportToXls,
        CardView
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            startDateCleared: false,
            endDateCleared: false,
            items: [],
            loading: false,
            downloadType: 'csv'
        }
    },
    mounted() {
        this.startDate = null
        this.endDate = null
        this.generateReport()
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                var responseData
                if (this.startDate && this.endDate && !this.startDateCleared && !this.endDateCleared)
                    responseData = await this.getItem(appConstants.EXPENSE_INCOME_REPORT + "?startDate=" + this.$options.filters.jsonDateFormat(this.startDate) + "&endDate=" + this.$options.filters.jsonDateFormat(this.endDate))
                else if (!this.endDate && !this.startDateCleared)
                    responseData = await this.getItem(appConstants.EXPENSE_INCOME_REPORT + "?startDate=" + this.$options.filters.jsonDateFormat(this.startDate))
                else
                    responseData = await this.getItem(appConstants.EXPENSE_INCOME_REPORT)
                this.items = responseData
                this.endDateCleared = false
                this.startDateCleared = false
                this.msg = null
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style scoped>
.report-heading {
    font-size: 1.2rem !important;
    font-weight: 500
}

td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    ;
}
</style>
